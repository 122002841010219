$(document).ready(function(){
  
  var locations = [
      ['Cagliari', 39.248362, 9.117630, 4],
  	  ['Quartucciu', 39.2552, 9.1798],
  ];

  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: 10,
    center: new google.maps.LatLng(39.248362, 9.117630),
    mapTypeId: google.maps.MapTypeId.ROADMAP
  });

  var infowindow = new google.maps.InfoWindow();

  var marker, i;

  for (i = 0; i < locations.length; i++) {  
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(locations[i][1], locations[i][2]),
      icon: '../images/pin-red.png',
      map: map
    });

    google.maps.event.addListener(marker, 'click', (function(marker, i) {
      return function() {
        infowindow.setContent(locations[i][0]);
        infowindow.open(map, marker);
      }
    })(marker, i));
  }
  
});


$(document).ready(function(){
  
  var locations2 = [
      ['Cagliari', 39.248362, 9.117630, 4],
  	  ['Bugerru', 39.399046, 8.400453],
  	  ['Carbonia', 39.164417, 8.521991],
  	  ['Carloforte', 39.140158, 8.302603],
  	  ['Domus De Maria', 38.940571, 8.869070],
  	  ['Domusnovas', 39.320985, 8.648991],
  ];

  var map2 = new google.maps.Map(document.getElementById('map-country'), {
    zoom: 10,
    center: new google.maps.LatLng(39.248362, 9.117630),
    mapTypeId: google.maps.MapTypeId.ROADMAP
  });

  var infowindow2 = new google.maps.InfoWindow();

  var marker2, i2;

  for (i2 = 0; i2 < locations2.length; i2++) {  
    marker2 = new google.maps.Marker({
      position: new google.maps.LatLng(locations2[i2][1], locations2[i2][2]),
      icon: '../images/pin-red.png',
      map: map2
    });

    google.maps.event.addListener(marker2, 'click', (function(marker2, i2) {
      return function() {
        infowindow.setContent(locations2[i2][0]);
        infowindow.open(map2, marker2);
      }
    })(marker2, i2));
  }
  
});
