$( document ).ready(function() {
   /*function addNow() {
      nowDate = moment().tz("Europe/London").format('YYYY-MM-DD');
      document.getElementById('registration-date').value = nowDate;
      set = setTimeout(function () { addNow(); }, 1000);
    }
    function stopNow() {
      clearTimeout(set);
    }*/
    $('.searchMobile').click(function(){
      $('.search-bar').toggleClass('open-search');
      $(this).toggleClass('toggled');
    });
    
    

    $('.gallery-home-content .gallery').slick({
      dots: true,
      infinite: true,
      speed: 500,
      arrows:true,
      fade: true,
      cssEase: 'linear'
    });
    
    var windowWidth = $(window).width();
    var imageHeight = $('.gallery-home-content .slick-slide figure').height();
    if(windowWidth<768){
      $('.gallery-home-content .slick-arrow').css('top', imageHeight-35);
      $('.gallery-home-content .slick-dots').css('top', imageHeight-30);        
    }
    
    $('.load-more').click(function(){
      $('.hidden-blocks').show().fadeIn(500);
    });
});



$(window).resize(function(){
  
  var windowWidth = $(window).width();
  var imageHeight = $('.gallery-home-content .slick-slider figure').height();
  if(windowWidth<768){
   // $('.gallery-home-content .slick-slider .slick-arrow').css('bottom', imageHeight-35);
   // $('.gallery-home-content .slick-slider .slick-dots').css('bottom', imageHeight-30);        
  }

});