$(document).ready(function(){
      var windowWidth = $(window).width();
      var target = 'full-video-carousel-content';
    function setupSlider(target){
    //carousel
      
      //var numSlides = $(target + ' > div').length;
      var numSlides = $(target).children('div').length;
      if(numSlides < 5 && numSlides > 2){
        var less = numSlides - 1
      }
      else if(numSlides == 1 || numSlides == 2){
        var less = 2
      }
      else{
        var less = 4
      }
      if(numSlides > 4){
        $(target).slick({
          slidesToShow: less,
          slidesToScroll:1,
          circular:false,
          arrows: true,
          centerMode: true,
          dots: false,
          infinite: true,
          responsive: [
              {
                breakpoint: 1170,
                settings: {
                  centerMode: true,
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  centerMode: true,
                  slidesToShow: 2
                }
              },
              {
                breakpoint: 767,
                settings: {
                  centerMode: false,
                  slidesToShow: 1
                }
              }
            ]
          });
      }
      else if(numSlides == 3 && windowWidth < 1501 && windowWidth > 767){
        $(target).slick({
          slidesToShow: less,
          slidesToScroll:1,
          circular:true,
          arrows: false,
          centerMode: true,
          dots: false,
          infinite: true
          });
      }
      else if(numSlides == 4 && windowWidth > 767){
        $(target).slick({
          slidesToShow: less,
          slidesToScroll:1,
          circular:true,
          arrows: false,
          centerMode: true,
          dots: false,
          infinite: true
          });
      }
      else if(numSlides == 4 && windowWidth < 768){
        $(target).slick({
          slidesToScroll:1,
          slidesToShow: 1,
          circular:true,
          arrows: false,
          dots: false,
          infinite: true
          });
      }
      else if(numSlides == 3 && windowWidth < 768){
        $(target).slick({
          slidesToScroll: 1,
          slidesToShow: 1,
          circular:true,
          centerMode: false,
          arrows: false,
          dots: false,
          infinite: true
          });
      }
      
     
      
      if(numSlides == 1 || numSlides == 2){
        $(target).parent('.full-video-carousel-content').addClass('float-element');
      };
      
      if(numSlides == 3 && windowWidth > 1500){
        $(target).parent('.full-video-carousel-content').addClass('float-element');
      };
      
      $(target).parent('.full-video-carousel-content').find('.arrow-sld-prev-custom').click(function() {
        $(target).slick('slickGoTo', parseInt($(target).slick('slickCurrentSlide'))-1);
      });
      $(target).parent('.full-video-carousel-content').find('.arrow-sld-next-custom').click(function() {
        $(target).slick('slickGoTo', parseInt($(target).slick('slickCurrentSlide'))+1);
      });
    }
    //fine setup
    
    
    
    $('.full-video-carousel').each( function(index,item){
      setupSlider(item);
    });
});