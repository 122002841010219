$( document ).ready(function() {

        $('.toggleFirstLevel').click(function() {
            $('#mobileFl').toggleClass('menu-mobile-opened');
        });
        $('.fixedCloseMenu').click(function(){
            $('div').removeClass('menu-mobile-opened');
        });
        $('#mobileFl > ul > li > a.open-sbl').click(function(){
            var menuTarget = $(this).data('target');
            
            if(!$('#'+menuTarget).hasClass('menu-mobile-opened')){
                $('#'+menuTarget).addClass('menu-mobile-opened');
            }
            
        });
        $('.goback-voice').click(function(){
           $(this).closest('.menu-mobile').removeClass('menu-mobile-opened');
        });
});


