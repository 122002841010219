$.fn.isOnScreen = function(){
    var win = $(window);
    
    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();
    
    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();
    
    console.log();    
    
    return (!(viewport.right < bounds.right || viewport.left > bounds.left || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

};