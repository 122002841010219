$( document ).ready(function() {
    setTimeout(function(){
        var widthW = jQuery(window).width();
        if(widthW >= 768){
            jQuery('.aside .section-side', '.main').each( function(){
                var HCenter = jQuery(this).parents('.main').find('.left-column').height();
                jQuery(this).css('minHeight',HCenter);
            });    
            jQuery('.sticky-adv').stick_in_parent({
               spacer:false
            });
        };
    },500);
   
    
});